/* eslint-disable max-len */
import types from '../types'
import Helpers from '../Helpers'

import {getNodes} from './getNodes'
import {getGraphs} from './getGraphs'
import {computeTsne} from './computeTsne'

export const uploadNodes = (database, vkgId, file) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, selectedGraphId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/nodes/file`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    const formdata = new FormData();
    formdata.append('file', file)
    Helpers.fetchPostMultipart(endpoint, headers, formdata)
        .then((res) => {
          if (res['status'] === 'SUCCESS' && !res.failedNodes) {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: 'Upload success',
                notificationType: 2,
                notificationIcon: 'check',
                isNotificationVisible: true,
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Upload failure: ${res.added} out of ${res.expected} nodes added. Check that the metadata is the same. Duplicate nodes (nodes with the same values/text) are blocked from being added`,
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                uploadGraphId: null,
              },
            })
          }
          if (selectedGraphId === vkgId) {
            dispatch(getNodes(database, vkgId, true))
          } else {
            dispatch(computeTsne(database, vkgId))
          }
          dispatch(getGraphs(database, ''))
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Upload failure',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
              uploadGraphId: null,
            },
          })
        })
  }
}
