// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.key-value-wrapper {
  padding: var(--spacing-01) var(--spacing-03);
  padding-right: var(--spacing-01half);
  max-width: 100%;
  width: -moz-fit-content;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  gap: var(--spacing-02);
  background-color: var(--colors-neutral-gray15);
  border: 1px solid var(--colors-semi-transparent-light20);
  border-radius: var(--radii-rounded);
}

.key-value {
  max-width: 100%;
  text-overflow: ellipsis;
  color: white;
  font-family: var(--font-families-normal);
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

.key {
  font-weight: 500;
}

.key-value-close {
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.key-value-close i {
  height: 18px;
  width: 18px;
  color: white;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/KeyValueWrapper.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,oCAAoC;EACpC,eAAe;EACf,uBAAkB;EAAlB,kBAAkB;EAClB,aAAa;EACb,+BAA+B;EAC/B,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,8CAA8C;EAC9C,wDAAwD;EACxD,mCAAmC;AACrC;;AAEA;EACE,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,wCAAwC;EACxC,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".key-value-wrapper {\n  padding: var(--spacing-01) var(--spacing-03);\n  padding-right: var(--spacing-01half);\n  max-width: 100%;\n  width: fit-content;\n  display: grid;\n  grid-template-columns: 1fr auto;\n  grid-template-rows: 1fr;\n  align-items: center;\n  gap: var(--spacing-02);\n  background-color: var(--colors-neutral-gray15);\n  border: 1px solid var(--colors-semi-transparent-light20);\n  border-radius: var(--radii-rounded);\n}\n\n.key-value {\n  max-width: 100%;\n  text-overflow: ellipsis;\n  color: white;\n  font-family: var(--font-families-normal);\n  font-size: 14px;\n  line-height: 21px;\n  text-align: center;\n  white-space: nowrap;\n  overflow: hidden;\n}\n\n.key {\n  font-weight: 500;\n}\n\n.key-value-close {\n  height: 18px;\n  width: 18px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.key-value-close i {\n  height: 18px;\n  width: 18px;\n  color: white;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
