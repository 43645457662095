/* eslint-disable max-len */
import Helpers from '../Helpers';
import types from '../types'

import {uploadNodes} from './uploadNodes';

export const createGraph = (database, vkg_name, file, metadata={}) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('create'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'You don\'t have create VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + '/api/vkgs'
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchPostJson(endpoint, headers, {'vkgId': vkg_name, 'metadata': metadata})
        .then((res) => {
          if (res.status === 'SUCCESS') {
            const new_metrics = userReducer.graphList
            new_metrics[vkg_name] = {name: vkg_name, numNodes: 0}
            const user_data = userReducer?.userData || {}
            const vkg_access = user_data?.vkgAccess || {}
            vkg_access[Helpers.encodeVKGId(vkg_name)] = 'read,write'
            user_data['vkgAccess'] = vkg_access
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                graphList: new_metrics,
                selectedGraphId: vkg_name,
                userData: user_data,
              },
            })
            Helpers.historyPush(`?vkgId=${vkg_name}`)
            dispatch(uploadNodes(database, vkg_name, file))
          } else {
            console.log(res?.error)
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Creation failure` + (res?.error?.message ? `: ${res?.error?.message}`: ''),
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Creation failure',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
