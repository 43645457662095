/* eslint-disable max-len */
import Helpers from '../Helpers';
import types from '../types'

import {getNodes} from './getNodes.js'
import {getGraphs} from './getGraphs.js'

export const editGraph = (database, body) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, selectedGraphId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(selectedGraphId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${selectedGraphId}`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchPutJson(endpoint, headers, body)
        .then((res) => {
          if (res?.status === 'SUCCESS') {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: 'Update success',
                notificationType: 2,
                notificationIcon: 'check',
                isNotificationVisible: true,
              },
            })
            dispatch(getGraphs(database))
            dispatch(getNodes(database, selectedGraphId, false))
          } else {
            console.log(res.error)
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Update failure` + (res?.error?.message ? `: ${res?.error?.message}`: ''),
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Update failure',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
