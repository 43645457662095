/* eslint-disable max-len */
import types from '../types'
import Helpers from '../Helpers'

export const searchGraph = (database, vkgId, query, metadata) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, searchSize, isFilterSearch} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('read') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('read'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'You don\'t have read VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/search`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    const data = {'queries': [query], 'numClosest': searchSize}
    data['metadataFilter'] = (metadata && isFilterSearch) ? metadata : {}
    return Helpers.fetchPostJson(endpoint, headers, data)
        .then((res) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
            },
          })
          return res
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Search failure',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
          return {}
        })
  }
}
