/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'
import {getInitialFilterState} from './getInitialFilterState'

export const getNodes = (database, vkgId, calculateTSNE) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        hasMetadata: false,
        isLoading: false,
        isGettingNodes: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('read') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('read'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        isGettingNodes: false,
        userMessage: 'You don\'t have read VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
        isGettingNodes: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/nodes`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchGetJson(endpoint, headers, {'do_tsne': calculateTSNE, 'include_stats': true})
        .then(async (res) => {
          if (!Object.keys(res?.nodes || {}).length) {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                isGettingNodes: false,
                userMessage: `${vkgId} doesn't have any nodes. You might not have access to this VKG or you may have to add data before trying to view this VKG.`,
                notificationType: 3,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                nodes: {},
                metadata: null,
                metadataValues: null,
                activeFilters: null,
                visualizations: null,
                isModal: true,
                isModalShowing: true,
                modalMode: 'menu',
              },
            })
          } else {
            const nodeIds = Object.keys(res.nodes)
            for (let nIndex = 0; nIndex < nodeIds.length; nIndex++) {
              const nodeInitMeta = res.nodes[nodeIds[nIndex]]?.['metadata'] || {}
              res.nodes[nodeIds[nIndex]]['metadata'] = Object.assign({...res.metadata}, nodeInitMeta)
            }
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                nodes: res.nodes,
                isLoading: false,
                isGettingNodes: false,
                hasMetadata: res?.statistics?.metadata && Object.keys(res?.statistics?.metadata).length,
                nodeStatistics: res?.statistics,
              },
            })
            const metadata = {}
            const metadataKeys = Object.keys(res.statistics?.metadata || {})
            if (metadataKeys.length) {
              for (let i = 0; i < metadataKeys.length; i++) {
                metadata[metadataKeys[i]] = Object.keys(res.statistics?.metadata?.[metadataKeys[i]]).includes('oneOf') ?
                  [...res.statistics?.metadata?.[metadataKeys[i]].oneOf] :
                  []
                if (Object.keys(res.statistics?.metadata?.[metadataKeys[i]]).includes('moreThan')) {
                  metadata[metadataKeys[i]].push(res.statistics?.metadata?.[metadataKeys[i]]?.['moreThan'])
                }
                if (Object.keys(res.statistics?.metadata?.[metadataKeys[i]]).includes('lessThan')) {
                  metadata[metadataKeys[i]].push(res.statistics?.metadata?.[metadataKeys[i]]?.['lessThan'])
                }
              }
              for (let i = 0; i < nodeIds.length; i++) {
                for (let j = 0; j < metadataKeys.length; j++) {
                  if (Object.keys(res.nodes?.[nodeIds[i]]?.['metadata'] || {}).includes(metadataKeys[j])) {
                    metadata[metadataKeys[j]].push(res.nodes[nodeIds[i]]['metadata'][metadataKeys[j]])
                  }
                }
              }
              for (let j = 0; j < metadataKeys.length; j++) {
                const metaType = res.statistics?.metadata?.[metadataKeys[j]]?.type
                metadata[metadataKeys[j]] = metadata[metadataKeys[j]].filter((val, index) => metadata[metadataKeys[j]].indexOf(val) === index)
                const metaTypeInference = Helpers.getInputType(metadata[metadataKeys[j]].filter((val) => val !== null)[0])
                if ((metaType === 'number' || metaType === 'datetime') || (metaTypeInference === 'number' || metaTypeInference === 'date')) {
                  metadata[metadataKeys[j]] = metadata[metadataKeys[j]].filter((val) => val !== null)
                }
                if (metaType !== 'number' && metaTypeInference !== 'number') {
                  metadata[metadataKeys[j]] = metadata[metadataKeys[j]].sort()
                } else {
                  metadata[metadataKeys[j]] = metadata[metadataKeys[j]].map((value) => parseFloat(value))
                  metadata[metadataKeys[j]] = metadata[metadataKeys[j]].sort((a, b) => {
                    return a - b
                  })
                }
              }
              const visualizations = {}
              for (let i = 0; i < metadataKeys.length; i++) {
                visualizations[metadataKeys[i]] = {
                  'isActive': false,
                }
              }
              const activeFilters = await getInitialFilterState(metadata)
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  metadataValues: metadata,
                  activeFilters: activeFilters,
                  visualizations: visualizations,
                  metadata: res.statistics.metadata,
                },
              })
            } else {
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  metadataValues: null,
                  activeFilters: null,
                  metadata: null,
                },
              })
            }
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              nodes: {},
              hasMetadata: false,
              isLoading: false,
              isGettingNodes: false,
              userMessage: `Failed to load in nodes for graph ${vkgId}.`,
              notificationType: 3,
              notificationIcon: 'warning',
              isNotificationVisible: true,
              metadata: null,
            },
          })
        })
  }
}


