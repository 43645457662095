/* eslint-disable max-len */
import React, {useState} from 'react';
import {connect} from 'react-redux';

import {Icon, Button} from '@lazarusai/forms-ui-components';
import Helpers from '../Helpers';
import {signOut} from '../actions/signOut'

import '../styles/Settings.css'

function UserSettings(props) {
  const [isOrgIdCopied, setIsOrgIdCopied] = useState(false)
  const [isAuthKeyCopied, setIsAuthKeyCopied] = useState(false)
  const [isUIDCopied, setIsUIDCopied] = useState(false)
  const [isBaseURLCopied, setIsBaseURLCopied] = useState(false)
  const [isOrgIdShowing, setIsOrgIdShowing] = useState(false)
  const [isAuthKeyShowing, setIsAuthKeyShowing] = useState(false)
  const [isUIDShowing, setIsUIDShowing] = useState(false)
  const [isBaseURLShowing, setIsBaseURLShowing] = useState(false)

  const hiddenString = '*************'

  const onClickCopyOrgId = () => {
    Helpers.copyToClipBoard(props.authForm.orgId, () => {
      setIsOrgIdCopied(true)
    })
    setTimeout(() => {
      setIsOrgIdCopied(false)
    }, 2000)
  }

  const onClickCopyAuthKey = () => {
    Helpers.copyToClipBoard(props.authForm.authKey, () => {
      setIsAuthKeyCopied(true)
    })
    setTimeout(() => {
      setIsAuthKeyCopied(false)
    }, 2000)
  }

  const onClickCopyUID = () => {
    Helpers.copyToClipBoard(props.userId, () => {
      setIsUIDCopied(true)
    })
    setTimeout(() => {
      setIsUIDCopied(false)
    }, 2000)
  }

  const onClickCopyBaseURL = () => {
    Helpers.copyToClipBoard(props.vkgDomain, () => {
      setIsBaseURLCopied(true)
    })
    setTimeout(() => {
      setIsBaseURLCopied(false)
    }, 2000)
  }

  function setOrgIdShowing(e) {
    if (props.userData?.permissions ? props.userData.permissions?.viewCredentials : props.userData?.role === 'admin') {
      e.preventDefault()
      setIsOrgIdShowing(!isOrgIdShowing)
    }
  }

  function setAuthKeyShowing(e) {
    if (props.userData?.permissions ? props.userData.permissions?.viewCredentials : props.userData?.role === 'admin') {
      e.preventDefault()
      setIsAuthKeyShowing(!isAuthKeyShowing)
    }
  }

  function setUIDShowing(e) {
    if (props.userData?.permissions ? props.userData.permissions?.viewCredentials : props.userData?.role === 'admin') {
      e.preventDefault()
      setIsUIDShowing(!isUIDShowing)
    }
  }

  function setBaseURLShowing(e) {
    if (props.userData?.permissions ? props.userData.permissions?.viewCredentials : props.userData?.role === 'admin') {
      e.preventDefault()
      setIsBaseURLShowing(!isBaseURLShowing)
    }
  }

  return (
    <>
      <div className='card-styles card-container'>
        <h3 className='font-ocr'>VKG Documentation</h3>
        <p className='card-title text-bright-green url-title'>
              Coming Soon
          {/* <a href={`${process.env.REACT_APP_DOCS_URL}`} target="_blank" rel="noopener noreferrer" >{process.env.REACT_APP_DOCS_URL}</a> */}
        </p>
      </div>
      {(props.userData?.permissions ? props.userData.permissions?.viewCredentials : props.userData?.role === 'admin') &&
      <div className='credentials-container'>
        <div className='card-styles card-container'>
          <h3 className='font-ocr'>VKG Base URL</h3>
          <p className='card-title text-bright-green url-title'>
            {isBaseURLShowing ? props.vkgDomain : hiddenString}
          </p>
          <div data-test-id='BaseURL-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
            <div
              className='flex cursor-pointer'
              onClick={setBaseURLShowing}
            >
              <div
                className={`toggle-container ${!isBaseURLShowing ? 'toggled' : ''}`}
              >
                <Icon icon='eye-outline' />
                <Icon icon='eye-off-2-outline' />
              </div>
              <span>{isBaseURLShowing ? 'Hide' : 'Show'}</span>
            </div>
            {isBaseURLShowing &&
            <div className='flex cursor-pointer' onClick={onClickCopyBaseURL}>
              <div
                className={`toggle-container ${isBaseURLCopied ? 'toggled' : ''}`}
              >
                <Icon icon='checkmark-outline' />
                <Icon icon='copy-outline' />
              </div>
              <span>{isBaseURLCopied ? 'Copied' : 'Copy to Clipboard'}</span>
            </div>
            }
          </div>
        </div>
        <div className='card-styles card-container'>
          <h3 className='font-ocr'>Org Id</h3>
          <p className='card-title text-bright-green'>
            {isOrgIdShowing ? props.authForm.orgId : hiddenString}
          </p>
          <div data-test-id='orgId-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
            <div
              className='flex cursor-pointer'
              onClick={setOrgIdShowing}
            >
              <div
                className={`toggle-container ${!isOrgIdShowing ? 'toggled' : ''}`}
              >
                <Icon icon='eye-outline' />
                <Icon icon='eye-off-2-outline' />
              </div>
              <span>{isOrgIdShowing ? 'Hide' : 'Show'}</span>
            </div>
            {isOrgIdShowing &&
            <div className='flex cursor-pointer' onClick={onClickCopyOrgId}>
              <div
                className={`toggle-container ${isOrgIdCopied ? 'toggled' : ''}`}
              >
                <Icon icon='checkmark-outline' />
                <Icon icon='copy-outline' />
              </div>
              <span>{isOrgIdCopied ? 'Copied' : 'Copy to Clipboard'}</span>
            </div>
            }
          </div>
        </div>
        <div className='card-styles card-container'>
          <h3 className='font-ocr'>Auth Key</h3>
          <p className='card-title text-bright-green'>
            {isAuthKeyShowing ? props.authForm.authKey : hiddenString}
          </p>
          <div data-test-id='authKey-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
            <div
              className='flex cursor-pointer'
              onClick={setAuthKeyShowing}
            >
              <div
                className={`toggle-container ${!isAuthKeyShowing ? 'toggled' : ''}`}
              >
                <Icon icon='eye-outline' />
                <Icon icon='eye-off-2-outline' />
              </div>
              <span>{isAuthKeyShowing ? 'Hide' : 'Show'}</span>
            </div>
            {isAuthKeyShowing &&
            <div className='flex cursor-pointer' onClick={onClickCopyAuthKey}>
              <div
                className={`toggle-container ${isAuthKeyCopied ? 'toggled' : ''}`}
              >
                <Icon icon='checkmark-outline' />
                <Icon icon='copy-outline' />
              </div>
              <span>{isAuthKeyCopied ? 'Copied' : 'Copy to Clipboard'}</span>
            </div>
            }
          </div>
        </div>
        <div className='card-styles card-container'>
          <h3 className='font-ocr'>User Id</h3>
          <p className='card-title text-bright-green'>
            {isUIDShowing ? props.userId : hiddenString}
          </p>
          <div data-test-id='uid-container' className='gap-group gap-group-columns gap-group-columns-auto justify-start'>
            <div
              className='flex cursor-pointer'
              onClick={setUIDShowing}
            >
              <div
                className={`toggle-container ${!isUIDShowing ? 'toggled' : ''}`}
              >
                <Icon icon='eye-outline' />
                <Icon icon='eye-off-2-outline' />
              </div>
              <span>{isUIDShowing ? 'Hide' : 'Show'}</span>
            </div>
            {isUIDShowing &&
            <div className='flex cursor-pointer' onClick={onClickCopyUID}>
              <div
                className={`toggle-container ${isUIDCopied ? 'toggled' : ''}`}
              >
                <Icon icon='checkmark-outline' />
                <Icon icon='copy-outline' />
              </div>
              <span>{isUIDCopied ? 'Copied' : 'Copy to Clipboard'}</span>
            </div>
            }
          </div>
        </div>
      </div>}
      <Button
        text='Log Out'
        icon={<Icon icon='log-out-outline' />}
        onClick={props.signOut}
        theme={props.theme}
        type='7'
        className={'log-out-btn'}
      />
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  vkgDomain: state.userReducer.vkgDomain,
  authForm: state.userReducer.authForm,
  userId: state.userReducer.userId,
  userData: state.userReducer.userData,
  theme: state.userReducer.theme,
})

export default connect(
    mapStateToProps,
    {signOut},
)(UserSettings)
