/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'
import {getGraphs} from './getGraphs.js'

export const deleteNode = (database, vkgId, node_id) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, graphList} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/nodes/batch`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchDeleteJson(endpoint, headers, {'nodes': [node_id]})
        .then(([ok, res]) => {
          if (ok) {
            const nodes = userReducer.nodes
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                nodes: {},
              },
            })
            delete nodes[node_id]
            if (graphList[vkgId]?.['nodes'] !== undefined) {
              graphList[vkgId]['nodes'] = graphList[vkgId]['nodes'] - 1
            } else if (graphList[vkgId]?.['numNodes']?.['num_nodes'] !== undefined) {
              graphList[vkgId]['numNodes']['num_nodes'] = graphList[vkgId]['numNodes']['num_nodes'] - 1
            } else {
              graphList[vkgId]['numNodes'] = graphList[vkgId]['numNodes'] - 1
            }
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                nodes: JSON.parse(JSON.stringify(nodes)),
                userMessage: `Successfully removed node ${node_id}.`,
                notificationType: 2,
                notificationIcon: 'check',
                isNotificationVisible: true,
                graphList: JSON.parse(JSON.stringify(graphList)),
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Failed to remove node ${node_id}` + (res?.error?.message ? `: ${res?.error?.message}`: '.'),
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
            dispatch(getGraphs(database))
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: `Failed to remove node ${node_id}.`,
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
