/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'

export const deleteNodes = (node_ids) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, selectedGraphId, graphList} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(selectedGraphId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${selectedGraphId}/nodes/batch`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    return Helpers.fetchDeleteJson(endpoint, headers, {'nodes': node_ids})
        .then(([ok, res]) => {
          if (ok) {
            const nodes = userReducer.nodes
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                nodes: {},
              },
            })
            for (let i = 0; i < node_ids.length; i++) {
              delete nodes[node_ids[i]]
            }
            if (graphList[selectedGraphId]?.['nodes'] !== undefined) {
              graphList[selectedGraphId]['nodes'] = graphList[selectedGraphId]['nodes'] - node_ids.length
            } else if (graphList[selectedGraphId]?.['numNodes']?.['num_nodes'] !== undefined) {
              graphList[selectedGraphId]['numNodes']['num_nodes'] = graphList[selectedGraphId]['numNodes']['num_nodes'] - node_ids.length
            } else {
              graphList[selectedGraphId]['numNodes'] = graphList[selectedGraphId]['numNodes'] - node_ids.length
            }
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                nodes: JSON.parse(JSON.stringify(nodes)),
                userMessage: `Successfully removed nodes.`,
                notificationType: 2,
                notificationIcon: 'check',
                isNotificationVisible: true,
                graphList: JSON.parse(JSON.stringify(graphList)),
              },
            })
            return true
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Failed to remove nodes` + (res?.error?.message ? `: ${res?.error?.message}`: '.'),
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                multiselectNodes: [],
              },
            })
            dispatch(getGraphs(database))
            return false
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: `Failed to remove nodes.`,
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
          return false
        })
  }
}
