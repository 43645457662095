/* eslint-disable max-len */
/* eslint-disable new-cap */
import React, {useEffect, useState, useRef, Fragment} from 'react'
import {connect} from 'react-redux'
import ForceGraph3D from '3d-force-graph'
import {useSearchParams} from 'react-router-dom'

import {Button, SearchBar, Icon, TabContainer, Switch} from '@lazarusai/forms-ui-components'

import * as THREE from 'three';
// import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import {OutlinePass} from 'three/addons/postprocessing/OutlinePass.js';
import {RenderPass} from 'three/addons/postprocessing/RenderPass.js';
import {SelectionBox} from 'three/addons/interactive/SelectionBox.js';
// import Stats from 'three/addons/libs/stats.module.js';

import Helpers from '../Helpers'

import {storePayload} from '../actions/storePayload'
import {searchGraph} from '../actions/searchGraph'
import {getNodes} from '../actions/getNodes'
import {getGraphs} from '../actions/getGraphs'
import {deleteNode} from '../actions/deleteNode'
import {updateNode} from '../actions/updateNode'
import Modal from './Modal'
import Settings from './Settings'
import Filter from './Filter'

// ICONS
// import Edit from '../images/edit-outline.svg'
// import Search from '../images/search-outline.svg'
// import Delete from '../images/trash-outline.svg'
// import FullView from '../images/expand-outline.svg'
// import LimitedView from '../images/collapse-outline.svg'
// import ZoomIn from '../images/maximize-outline.svg'
// import ZoomOut from '../images/minimize-outline.svg'
// import More from '../images/more-horizontal-outline.svg'

// HUD SVGS
import CrossHairs from '../images/middle-crosshair.svg'
import Confidence from '../images/percent-ring.svg'
import Indices from '../images/indices.svg'
import Pointer from '../images/pointer-ring.svg'
import Frame from '../images/outer-frame.svg'
import MultiselectPreview from './MultiselectPreview'
import MetadataTable from './MetadataTable'

/**
 * Graph
 * - this is the main vkg component.
 *
 * There are a lot of sections to this file:
 * - State / Ref Declarations
 * - State / Ref Combined Functions
 * - Node Distance Functions
 * - Color Declarations
 * - Distance Declarations
 * - Color Functions
 * - Data Formating Functions
 * - Use Effects / Graph Loading
 * - View Functions
 * - Action / Button Functions
 * - Selection Functions
 * - Camera Util Function(s)
 * - DOM Formatting Functions
 * - Return
 *
 * These section heads have been commented in the code so that Cmd+f can be used for navigating
 */

function Graph(props) {
  // State / Ref Declarations
  const myGraph = ForceGraph3D();
  const graphRef = useRef();
  const selectedNode = useRef(null);
  const fullNodesRef = useRef([]);
  const selectedCoordinates = useRef([0, 0, 0]);
  const searchCoordinatesRef = useRef([0, 0, 0]);
  const [_selectedNode, _setSelectedNode] = useState(null);
  const [_textValue, _setTextValue] = useState(null)
  const [nodeDataShowing, setNodeDataShowing] = useState(false);
  const [nodeDataExpanded, setNodeDataExpanded] = useState(false);
  const [nodeTabState, setNodeTabState] = useState('text')
  const [editMode, setEditMode] = useState(false)
  const [updatedValue, setUpdatedValue] = useState('')
  const [_coordinates, _setCoordinates] = useState(null)
  const [nodeId, setNodeId] = useState(null)
  const [activeSearch, _setActiveSearch] = useState(null)
  const activeSearchRef = useRef(null)
  const selectedNodeObjectRef = useRef(null)
  const [_selectedNodeObject, _setSelectedNodeObject] = useState(null)

  const highlightedNodes = useRef([]);
  const hoverHighlightedNodes = useRef([]);
  const multiselectNodes = useRef([]);
  const hoverHighlightTimeout = useRef(null);

  const [activeMultiselect, setActiveMultiselect] = useState(false)
  const [numberMultiselect, setNumberMultiselect] = useState(0)

  const [multiPreviewUnselect, setMultiPreviewUnselect] = useState([])
  const [multiPreviewUnselectCount, setMultiPreviewUnselectCount] = useState(0)

  const hoverTextRef = useRef(null);
  const [hoverText, _setHoverText] = useState(null);

  const [_limitedView, _setLimitedView] = useState(false);
  const limitedViewRef = useRef(false);
  const previousDistanceRef = useRef(4000);

  const [zoomVal, setZoomVal] = useState(50);
  const [searchString, setSearchString] = useState('');

  const [confirmDelete, setConfirmDelete] = useState(false);
  // const [isNodeViewDropdown, setIsNodeViewDropdown] = useState(false);
  const [isFilterShowing, setIsFilterShowing] = useState(false)

  const graphCamera = useRef(null);
  const graphControls = useRef(null);
  const graphScene = useRef(null);
  // const statsRef = useRef(null);
  const visualRef = useRef(false);
  const visualizationsRef = useRef(undefined);
  const filterRef = useRef(false);
  const activeFiltersRef = useRef(undefined);
  const metadataRef = useRef(undefined);

  const [searchParam] = useSearchParams()

  // State / Ref Combined Functions
  // state - needed for rerender
  // ref - needed for accurate value
  function setActiveSearch(val) {
    _setActiveSearch(val)
    activeSearchRef.current = val
  }

  function setTextValue(text) {
    _setTextValue(text)
  }

  function setCoordinates(coors, distance = 40) {
    _setCoordinates(coors)
    if (coors) {
      zoomTo(coors[0], coors[1], coors[2], distance)
    }
  }

  function setHoverText(text) {
    hoverTextRef.current = text
    _setHoverText(text)
  }

  function setSelectedNode(newSelectedNode) {
    selectedNode.current = newSelectedNode
    _setSelectedNode(newSelectedNode)
  }

  function setLimitedView(view) {
    _setLimitedView(view)
    limitedViewRef.current = view
  }

  function setSelectedNodeObject(obj) {
    selectedNodeObjectRef.current = JSON.parse(JSON.stringify(obj))
    _setSelectedNodeObject(JSON.parse(JSON.stringify(obj)))
  }

  // Node Distance Functions
  function distanceToSelectedNode(node) {
    const sel = selectedCoordinates.current // just to make eq. clean
    return Math.sqrt((node.x - sel[0]) ** 2 + (node.y - sel[1]) ** 2 + (node.z - sel[2]) ** 2)
  }

  function distanceToHoverNode(hover, node) {
    return Math.sqrt((node.x - hover.x) ** 2 + (node.y - hover.y) ** 2 + (node.z - hover.z) ** 2)
  }

  function arrayDistance(a, b) {
    return Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2 + (a[2] - b[2]) ** 2)
  }

  // Color Declarations
  const DEFAULT_COLOR = '#5F5F5F'
  const MULTISELECT_COLOR = '#51FFC8'
  const SELECTED_COLOR = '#FF0000'
  const HIGHLIGHTED_COLOR = '#FC6A03'
  const HOVER_HIGHLIGHTED_COLOR = '#FCAE1E'
  const BACKGROUND = '#000000'

  // Distance Declarations
  // const LIMIT_CUTOFF = 250;
  const MAX_DISTANCE = 4000;
  const MIN_DISTANCE = 120;


  // Color Functions
  function showSelectedHighlightedNodes(node) {
    if (multiselectNodes.current.includes(node.pos)) {
      return MULTISELECT_COLOR
    }
    if (selectedNode.current === node.pos) {
      return SELECTED_COLOR
    }
    if (highlightedNodes.current.includes(node.pos)) {
      return HIGHLIGHTED_COLOR
    }
    if (hoverHighlightedNodes.current.includes(node.pos)) {
      return HOVER_HIGHLIGHTED_COLOR
    }
    return DEFAULT_COLOR
  }

  function defaultColorFunction(n) {
    n.fx = n.x
    n.fy = n.y
    n.fz = n.z
    return DEFAULT_COLOR
    // return '#2a2a2a'
  }

  function specialColorFunction(node) {
    node.fx = node.x
    node.fy = node.y
    node.fz = node.z
    const colorKey = visualRef.current?.['string']?.['key']
    if (!colorKey) {
      return DEFAULT_COLOR
    }
    const metaVal = node.metadata?.[colorKey]
    const visColor = visualRef.current?.['string']?.['color']?.[metaVal]
    return visColor? visColor: DEFAULT_COLOR
  }

  function opacityValueFunction(node) {
    const opacKey = visualRef.current?.['number']?.['key']
    if (!opacKey) {
      return 1
    }
    if (!node.metadata[opacKey]) {
      return .4
    } else if (metadataRef.current[opacKey][0]?.length || metadataRef.current[opacKey][metadataRef.current[opacKey].length]?.length) {
      // Dates
      const startDate = new Date(metadataRef.current[opacKey][0])
      const endDate = new Date(metadataRef.current[opacKey][metadataRef.current[opacKey].length - 1])
      const nodeDate = new Date(node.metadata[opacKey])
      const totalDifference = endDate - startDate
      const nodeDifference = nodeDate - startDate
      const opacityChange = .6 * (nodeDifference/totalDifference)
      return .4 + (opacityChange !== NaN ? opacityChange : 0)
    } else {
      // Numbers
      const totalDifference = metadataRef.current[opacKey][metadataRef.current[opacKey].length - 1] - metadataRef.current[opacKey][0]
      const nodeDifference = node.metadata[opacKey] - metadataRef.current[opacKey][0]
      const opacityChange = .6 * (nodeDifference/totalDifference)
      return .4 + opacityChange
    }
  }

  function defaultShapeFunction(node) {
    // return new THREE.DodecahedronGeometry(5)
    const shapeKey = visualRef.current?.['boolean']?.['key']
    if (shapeKey) {
      const metaVal = node.metadata[shapeKey]
      const shapePosition = (metaVal === true || (metaVal || '').toLowerCase() === 'true') ? 0: metaVal === null? 2: 1
      return [
        new THREE.BoxGeometry(10, 10, 10),
        new THREE.TetrahedronGeometry(10),
        new THREE.SphereGeometry(5),
      ][shapePosition]
    } else {
      return new THREE.SphereGeometry(5)
    }
  }

  function isNodeInFilter(node) {
    for (let i = 0; i < filterRef.current.length; i++) {
      if (filterRef.current[i].type === 'list') {
        if (!Object.values(filterRef.current[i].value).includes(node.metadata[filterRef.current[i].key])) {
          return false
        }
      } else {
        const isValueInsideRange = node.metadata[filterRef.current[i].key] >= filterRef.current[i].value.range.from &&
          node.metadata[filterRef.current[i].key] <= filterRef.current[i].value.range.to
        if (!isValueInsideRange) {
          return false
        }
      }
    }
    return true
  }

  // function wrapNodeThreeObject(
  //     node,
  //     colorFunc=defaultColorFunction,
  //     shapeFunc=defaultShapeFunction,
  // ) {
  //   console.log('here')
  //   return new THREE.Mesh(
  //       shapeFunc(node),
  //       new THREE.MeshLambertMaterial({
  //         color: colorFunc(node),
  //         transparent: true,
  //         opacity: 1,
  //         map: null,
  //       }),
  //   )
  // }

  function nodeColorWrapper(colorFunc=defaultColorFunction) {
    // this allows for changed colors, while keeping the shape the same
    if (!visualRef.current.string && !visualRef.current.boolean && !visualRef.current.number && !filterRef.current.length) {
      graphRef.current.nodeColor((n) => {
        n.fx = n.x
        n.fy = n.y
        n.fz = n.z
        return colorFunc(n)
      })
      clearBorders()
    } else {
      nodeBorderStyle()
    }
  }

  function clearBorders() {
    if (graphCamera.current && graphScene.current) {
      const renderPass = new RenderPass(graphScene.current, graphCamera.current)
      graphRef.current.postProcessingComposer().passes = []
      graphRef.current.postProcessingComposer().reset()
      graphRef.current.postProcessingComposer().addPass(renderPass)
      graphRef.current.postProcessingComposer().render()
    }
  }

  function nodeBorderColor(colorFunc=showSelectedHighlightedNodes) {
    const multiObjs = []
    const selectObjs = []
    const searchObjs = []
    const hoverObjs = []
    graphScene.current.traverse((obj) => {
      if (obj.isMesh && obj.parent.type === 'Group') {
        const color = colorFunc(obj.__data)
        if (color === MULTISELECT_COLOR) {
          multiObjs.push(obj)
        } else if (color === SELECTED_COLOR) {
          selectObjs.push(obj)
        } else if (color === HIGHLIGHTED_COLOR) {
          searchObjs.push(obj)
        } else if (color === HOVER_HIGHLIGHTED_COLOR) {
          hoverObjs.push(obj)
        }
      }
    })
    // Render
    const renderPass = new RenderPass(graphScene.current, graphCamera.current)
    graphRef.current.postProcessingComposer().passes = []
    graphRef.current.postProcessingComposer().reset()
    graphRef.current.postProcessingComposer().addPass(renderPass)
    for (let i = 0; i < 4; i++) {
      const objs = [multiObjs, selectObjs, searchObjs, hoverObjs][i]
      if (objs.length) {
        const outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), graphScene.current, graphCamera.current, objs)
        outlinePass.renderToScreen = true;
        outlinePass.selectedObjects = objs;
        graphRef.current.postProcessingComposer().addPass(outlinePass);
        const params = {
          edgeStrength: 5,
          edgeGlow: 0,
          edgeThickness: 2.0,
          pulsePeriod: 0,
          usePatternTexture: false,
        };
        outlinePass.edgeStrength = params.edgeStrength;
        outlinePass.edgeGlow = params.edgeGlow;
        const color = [MULTISELECT_COLOR, SELECTED_COLOR, HIGHLIGHTED_COLOR, HOVER_HIGHLIGHTED_COLOR][i]
        outlinePass.visibleEdgeColor.set(color);
        outlinePass.hiddenEdgeColor.set(color);
      }
    }
    graphRef.current.postProcessingComposer().render()
  }

  function renderFilterObjects(colorFunc=showSelectedHighlightedNodes) {
    const multiObjs = []
    const selectObjs = []
    const searchObjs = []
    const hoverObjs = []
    graphScene.current.traverse((obj) => {
      if (obj.isMesh && obj.parent.type === 'Group') {
        obj.geometry.dispose()
        obj.geometry = defaultShapeFunction(obj.__data)
        const materialObj = obj.material.clone()
        const colorObj = obj.material.color.clone()
        colorObj.set(specialColorFunction(obj.__data))
        materialObj.color.set(colorObj)
        materialObj.opacity = (obj.__data.pos%6)/5
        obj.material = materialObj
        // Border Part
        const color = colorFunc(obj.__data)
        if (color === MULTISELECT_COLOR) {
          multiObjs.push(obj)
        } else if (color === SELECTED_COLOR) {
          selectObjs.push(obj)
        } else if (color === HIGHLIGHTED_COLOR) {
          searchObjs.push(obj)
        } else if (color === HOVER_HIGHLIGHTED_COLOR) {
          hoverObjs.push(obj)
        }
      }
    })
    const renderPass = new RenderPass(graphScene.current, graphCamera.current)
    graphRef.current.postProcessingComposer().passes = []
    graphRef.current.postProcessingComposer().reset()
    graphRef.current.postProcessingComposer().addPass(renderPass)
    for (let i = 0; i < 4; i++) {
      const objs = [multiObjs, selectObjs, searchObjs, hoverObjs][i]
      if (objs.length) {
        const outlinePass = new OutlinePass( new THREE.Vector2( window.innerWidth, window.innerHeight ), graphScene.current, graphCamera.current, objs)
        outlinePass.renderToScreen = true;
        outlinePass.selectedObjects = objs;
        graphRef.current.postProcessingComposer().addPass(outlinePass);
        const params = {
          edgeStrength: 5,
          edgeGlow: 0,
          edgeThickness: 2.0,
          pulsePeriod: 0,
          usePatternTexture: false,
        };
        outlinePass.edgeStrength = params.edgeStrength;
        outlinePass.edgeGlow = params.edgeGlow;
        const color = [MULTISELECT_COLOR, SELECTED_COLOR, HIGHLIGHTED_COLOR, HOVER_HIGHLIGHTED_COLOR][i]
        outlinePass.visibleEdgeColor.set(color);
        outlinePass.hiddenEdgeColor.set(color);
      }
    }
    graphRef.current.postProcessingComposer().render()
  }

  function nodeBorderStyle() {
    // the below code before the if-else is also in the nodeColorStyle function
    // this function was separated to speed the process
    const visualObj = Object.assign(
        {},
        ...Object.keys(visualizationsRef.current).map((visKey) => {
          const returnObj = {}
          returnObj[visualizationsRef.current[visKey].type] = {'key': visKey, ...visualizationsRef.current[visKey]}
          return visualizationsRef.current[visKey].isActive ? returnObj: {}
        }),
    )
    visualRef.current = visualObj
    const activeFiltersList = Object.keys(activeFiltersRef.current || {}).map((metaKey) => {
      const returnObj = {'key': metaKey, 'value': {...activeFiltersRef.current[metaKey]}}
      if (Object.keys(activeFiltersRef.current[metaKey]).includes('range')) {
        returnObj['type'] = 'range'
        const fromVal = activeFiltersRef.current[metaKey].range?.from
        const toVal = activeFiltersRef.current[metaKey].range?.to
        const fromType = Helpers.getInputType(fromVal)
        const toType = Helpers.getInputType(toVal)
        return ((fromType === 'date' && toType === 'date') || (fromType === 'number' && toType === 'number')) && (fromVal <= toVal) ?
          returnObj: null
      }
      returnObj['type'] = 'list'
      return activeFiltersRef.current[metaKey].length ? returnObj: null
    }).filter((val) => val)
    filterRef.current = activeFiltersList
    const isVisualOn = Object.keys(visualObj).length ? true: false

    if (isVisualOn || activeFiltersList.length) {
      nodeBorderColor(showSelectedHighlightedNodes)
    } else {
      clearBorders()
      graphRef.current.nodeColor((n) => {
        n.fx = n.x
        n.fy = n.y
        n.fz = n.z
        return showSelectedHighlightedNodes(n)
      })
    }
  }

  function nodeColorStyle() {
    if (graphRef.current && (visualizationsRef.current || activeFiltersRef.current)) {
      const visualObj = Object.assign(
          {},
          ...Object.keys(visualizationsRef.current).map((visKey) => {
            const returnObj = {}
            returnObj[visualizationsRef.current[visKey].type] = {'key': visKey, ...visualizationsRef.current[visKey]}
            return visualizationsRef.current[visKey].isActive ? returnObj: {}
          }),
      )
      visualRef.current = visualObj
      const activeFiltersList = Object.keys(activeFiltersRef.current || {}).map((metaKey) => {
        const returnObj = {'key': metaKey, 'value': {...activeFiltersRef.current[metaKey]}}
        if (Object.keys(activeFiltersRef.current[metaKey]).includes('range')) {
          returnObj['type'] = 'range'
          const fromVal = activeFiltersRef.current[metaKey].range?.from
          const toVal = activeFiltersRef.current[metaKey].range?.to
          const fromType = Helpers.getInputType(fromVal)
          const toType = Helpers.getInputType(toVal)
          return ((fromType === 'date' && toType === 'date') || (fromType === 'number' && toType === 'number')) && (fromVal <= toVal) ?
            returnObj: null
        }
        returnObj['type'] = 'list'
        return activeFiltersRef.current[metaKey].length ? returnObj: null
      }).filter((val) => val)
      filterRef.current = activeFiltersList
      const isVisualOn = Object.keys(visualObj).length ? true: false
      // graphRef.current.nodeThreeObject((node) => {
      //   return wrapNodeThreeObject(node, specialColorFunction)
      // })
      // if (!isVisualOn && !activeFiltersList.length) {
      //   graphRef.current.nodeThreeObject((node) => {
      //     return null
      //   })
      // }
      if (isVisualOn || activeFiltersList.length) {
        graphScene.current.traverse((obj) => {
          if (obj.isMesh && obj.parent.type === 'Group') {
            if (!isNodeInFilter(obj.__data)) { // GHOST NODE - outside filter
              obj.geometry.dispose()
              obj.geometry = new THREE.SphereGeometry(5)
              const materialObj = obj.material.clone()
              const colorObj = obj.material.color.clone()
              colorObj.set('#2a2a2a')
              materialObj.color.set(colorObj)
              materialObj.opacity = .8
              obj.material = materialObj
            } else if (isVisualOn) {
              obj.geometry.dispose()
              obj.geometry = defaultShapeFunction(obj.__data)
              const materialObj = obj.material.clone()
              const colorObj = obj.material.color.clone()
              colorObj.set(specialColorFunction(obj.__data))
              materialObj.color.set(colorObj)
              materialObj.opacity = opacityValueFunction(obj.__data)
              obj.material = materialObj
            } else {
              obj.geometry.dispose()
              obj.geometry = new THREE.SphereGeometry(5)
              const materialObj = obj.material.clone()
              const colorObj = obj.material.color.clone()
              colorObj.set(DEFAULT_COLOR)
              materialObj.color.set(colorObj)
              materialObj.opacity = 1
              obj.material = materialObj
            }
          }
        })
      }
    }
  }

  function onNodeHover(node) {
    if (node) {
      setHoverText(node.id)
      const closeNodes = [node.pos]
      for (let i = 0; i < fullNodesRef.current.length; i++) {
        if (node && i !== node.pos && distanceToHoverNode(node, fullNodesRef.current[i]) < 50) {
          closeNodes.push(fullNodesRef.current[i].pos)
        }
      }
      hoverHighlightedNodes.current = closeNodes
      nodeColorWrapper(showSelectedHighlightedNodes)
      if (hoverHighlightTimeout.current !== null) {
        window.clearTimeout(hoverHighlightTimeout.current)
      }
      hoverHighlightTimeout.current = window.setTimeout(() => {
        hoverTimeout()
      }, 5000);
    }
  }

  function hoverTimeout() {
    hoverHighlightedNodes.current = []
    nodeColorWrapper(showSelectedHighlightedNodes)
    setHoverText(null)
  }

  // Opacity Function

  // function alterOpacity(node) {
  //   console.log(node)
  //   if (node.x > 50 || node.x < -50) {
  //     const objThree = node.__threeObj
  //     objThree.materials[0].opacity = .05
  //     return objThree
  //   } else {
  //     const objThree = node.__threeObj
  //     objThree.materials[0].opacity = .99
  //     return objThree
  //   }
  // }

  // Data Formating Functions

  // function dataFormat(data) {
  //   // read from json
  //   const ret_data = [];
  //   for (let i = 0; i < data['vectors'].length; i++) {
  //     ret_data.push({
  //       pos: i,
  //       id: data['text'][i],
  //       x: 10 * data['vectors'][i][0],
  //       y: 10 * data['vectors'][i][1],
  //       z: 10 * data['vectors'][i][2],
  //     })
  //   }
  //   return ret_data
  // }

  // function dataFormatFirebase(data) {
  //   // read from firebase nodes
  //   const ret_data = [];
  //   for (let i = 0; i < Object.keys(data).length; i++) {
  //     ret_data.push({
  //       pos: i,
  //       id: data[`${i}`]['value'],
  //       x: 10 * data[`${i}`]['tsne_vector'][0],
  //       y: 10 * data[`${i}`]['tsne_vector'][1],
  //       z: 10 * data[`${i}`]['tsne_vector'][2],
  //     })
  //   }
  //   // console.log(ret_data.length)
  //   return ret_data
  // }

  function tsneScaleFactor(numNodes) {
    const linearRange = ((numNodes / 100) > 10) ? 10 : (numNodes / 100)
    const logRange = (numNodes > 1000) ? (Math.log10(numNodes) - 3) : 0
    return 1 + linearRange + logRange
  }

  function dataFormatRedis(data) {
    // read from firebase nodes
    const ret_data = [];
    if (data) {
      const data_keys = Object.keys(data);
      const isNewStructure = Object.keys(data[data_keys[0]]).includes('text')
      const tsneScale = tsneScaleFactor(data_keys.length)
      if (isNewStructure) {
        let posCounter = 0
        for (let i = 0; i < data_keys.length; i++) {
          if (data[data_keys[i]]?.tsne) {
            ret_data.push({
              pos: posCounter,
              id: data[data_keys[i]]['text'],
              x: tsneScale * data[data_keys[i]]['tsne'][0],
              y: tsneScale * data[data_keys[i]]['tsne'][1],
              z: tsneScale * data[data_keys[i]]['tsne'][2],
              metadata: data[data_keys[i]]['metadata'],
              node_id: data_keys[i],
            })
            posCounter = posCounter + 1
          }
        }
      } else {
        for (let i = 0; i < data_keys.length; i++) {
          ret_data.push({
            pos: i,
            id: data[data_keys[i]][0],
            x: tsneScale * data[data_keys[i]][1][0],
            y: tsneScale * data[data_keys[i]][1][1],
            z: tsneScale * data[data_keys[i]][1][2],
            metadata: null,
            node_id: data_keys[i],
          })
        }
      }
    }
    return ret_data
  }

  // function dataReformat(nodes) {
  //   // recalculate node positions
  //   for (let i = 0; i < Object.keys(nodes).length; i++) {
  //     nodes[i]['pos'] = i
  //   }
  //   return nodes
  // }

  // Use Effects / Graph Loading

  // re highlighting
  useEffect(() => {
    if (graphRef.current) {
      nodeColorWrapper(showSelectedHighlightedNodes)
    }
  }, [highlightedNodes.current])

  // initial load useEffect
  useEffect(() => {
    if (props.vkgDomain) {
      props.getGraphs(props.database)
      const graph = document.getElementById('graph')
      myGraph.width(graph.offsetWidth);
      myGraph.height(graph.offsetHeight);
      myGraph.backgroundColor(BACKGROUND);
      myGraph.showNavInfo(false)
      myGraph.cooldownTicks(0);
      myGraph.enableNodeDrag(false);
      myGraph(graph)
      graphRef.current = myGraph
    }
  }, [props.vkgDomain])

  useEffect(() => {
    visualizationsRef.current = props.visualizations
    activeFiltersRef.current = props.activeFilters
    visualRef.current = false
    filterRef.current = false
    nodeColorStyle()
  }, [props.visualizations, props.activeFilters])

  useEffect(() => {
    metadataRef.current = props.metadataValues
  }, [props.metadataValues])

  useEffect(() => { // Reset graph view if selectedGraphId is changed
    if (graphRef?.current?.graphData) {
      resetGraphView()
    }
  }, [props.selectedGraphId])

  // Handles case when currently selected graph is deleted or when first graph is created and graphCamera is already set
  // If other graph exists, resets view to default graph. Otherwise, resets view to blank.
  useEffect(() => { // Handle null selectedGraphId due to graph deletion
    if (!props.selectedGraphId) {
      if (props.graphList && Object.keys(props.graphList).length && graphCamera.current) {
        const vkg_id = Object.keys(props.graphList).sort()[0] // Set vkg to top graph in graphList
        if (!props.isGettingNodes) { // Ensures not already calling getNodes to prevent db lock error (would otherwise sometimes occur upon graph creation)
          props.storePayload({
            selectedGraphId: vkg_id, // Set selectedGraphId
          })
          Helpers.historyPush(`?vkgId=${vkg_id}`) // Adjust vkgId url param
          props.getNodes(props.database, vkg_id, false) // Update graph nodes
        }
      } else {
        Helpers.historyPush('/') // Remove vkgId url param if graphList is empty
      }
    }
  }, [props.graphList])

  // initializing graph after list update use effect and a selectedGraphId isn't already selected
  // Only runs once upon page load (because graphCamera is not yet set)
  useEffect(() => {
    if (props.graphList && Object.keys(props.graphList).length && !graphCamera.current) {
      const vkg_id = (Object.keys(props.graphList).includes(searchParam.get('vkgId'))) ? searchParam.get('vkgId') : props.selectedGraphId || Object.keys(props.graphList).sort()[0]
      // either link specified vkg (if legit id) or selected or default first
      if (!props.isGettingNodes) { // Ensures not already calling getNodes to prevent db lock error (would otherwise sometimes occur upon graph creation)
        props.storePayload({
          selectedGraphId: vkg_id,
        })
        Helpers.historyPush(`?vkgId=${vkg_id}`)
        props.getNodes(props.database, vkg_id, false)
        graphRef.current.cooldownTicks(0);
        graphRef.current.enableNodeDrag(false);
        graphRef.current.nodeColor((n) => {
          n.fx = n.x
          n.fy = n.y
          n.fz = n.z
          return DEFAULT_COLOR
        })
        graphRef.current.nodeOpacity(1)
        graphRef.current.showNavInfo(false)
        graphRef.current.onNodeClick((node, event) => {
          nodeClick(node, event)
          if (document.selection) {
            document.selection.empty()
          }
          if (window.getSelection) {
            const sel = window.getSelection()
            sel.removeAllRanges()
          }
        })
        graphCamera.current = graphRef.current.camera()
        graphScene.current = graphRef.current.scene()
        // adding stats
        // statsRef.current = new Stats()
        // const graph = document.getElementById('stats')
        // graph.appendChild(statsRef.current.dom)
        // setInterval(() => {
        //   statsRef.current.update()
        // }, 50)
        graphRef.current.onNodeHover(onNodeHover)
        graphRef.current.nodeLabel(labelCreation)
        const bottomLight = new THREE.DirectionalLight(0xffffff, .6)
        bottomLight.position.y = -1
        document.onkeydown = (e) => {
          readKeys(e)
        }
        graphControls.current = graphRef.current.controls()
        graphControls.current.addEventListener('change', cameraPositionChange);
        graphControls.current.minDistance = MIN_DISTANCE;
        graphControls.current.maxDistance = MAX_DISTANCE;
        const sideLight = new THREE.DirectionalLight(0xffffff, .6)
        sideLight.position.y = 0
        sideLight.position.x = 1
        graphScene.current.add(sideLight)
      }
    }
  }, [props.graphList, props.nodes])

  function labelCreation(node) {
    const hasMetadata = Object.keys(node.metadata || {}).length ? true : false
    return true ?
      `<div class='node-label'>
        <p class='preview-title'>
          Preview
        </p>
        <p class='preview-body'>
          ${node.id}
        </p>
        <div class='metadata-section ${hasMetadata ? '': 'display-none'}'>
          ${Object.keys(node?.metadata || []).map((metadataKey, i) => {
        return `<div class='metadata-kvp'><b>${metadataKey}</b>: ${node?.metadata[metadataKey]}</div>`
      }).join('\n          ')}
        </div>
        <div class='${hasMetadata ? 'meta-footer': 'meta-footer display-none'}'>${Object.keys(node?.metadata || []).length} total metadata tags</div>
      </div>`:
      null
  }

  function calculateZoom(dist) {
    return 100 - 100 * (dist - MIN_DISTANCE)/(MAX_DISTANCE - MIN_DISTANCE)
  }

  // listener function for switching between limited and full view
  function cameraPositionChange(e) {
    const dist = distanceToSelectedNode(graphCamera.current.position)
    setZoomVal(calculateZoom(dist))
    previousDistanceRef.current = dist
  }

  // key reading function
  function readKeys(e) {
    if (e.target.tagName === 'BODY') {
      if (e.key === 'ArrowUp') {
        zoomMath(true)
      } else if (e.key === 'ArrowDown') {
        zoomMath(false)
      }
    }
  }

  // node click function
  function nodeClick(node, event) {
    if (!event.shiftKey) {
      setSelection(node.id, [node.x, node.y, node.z], node.node_id)
      setSelectedNode(node.pos)
      setSelectedNodeObject(node)
      setNodeTabState('text')
      setHoverText(null)
      setNodeDataShowing(true)
      // setIsNodeViewDropdown(false)
      setUpdatedValue(node.id)
      setEditMode(false)
      selectedCoordinates.current = [node.x, node.y, node.z]

      if ((limitedViewRef.current && highlightedNodes.current.includes(node.pos)) || !activeSearchRef.current) {
        setLimitedView(true)
      }

      if (!activeSearchRef.current) { // only change highlights when not searching
        const closeNodes = [node.pos]
        for (let i = 0; i < fullNodesRef.current.length; i++) {
          if (node && i !== node.pos && distanceToSelectedNode(fullNodesRef.current[i]) < 50) {
            closeNodes.push(fullNodesRef.current[i].pos)
          }
        }
        highlightedNodes.current = closeNodes
      }

      const distance = 120;
      // const distRatio = 1 + distance/Math.hypot(node.x, node.y, node.z);

      // const newPos = node.x || node.y || node.z ?
      // {x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio} :
      // {x: 0, y: 0, z: distance};

      const camPosition = graphRef.current.camera().position
      const camDist = distanceToHoverNode(camPosition, node)
      const distRatio = distance / camDist
      const invDistRatio = 1 - distance / camDist

      const newPos = (camDist > distance) ?
      {
        x: camPosition.x + (node.x - camPosition.x) * invDistRatio,
        y: camPosition.y + (node.y - camPosition.y) * invDistRatio,
        z: camPosition.z + (node.z - camPosition.z) * invDistRatio,
      } :
      {
        x: camPosition.x - (node.x - camPosition.x) * distRatio,
        y: camPosition.y - (node.y - camPosition.y) * distRatio,
        z: camPosition.z - (node.z - camPosition.z) * distRatio,
      }

      // The zooming doesn't really work, it is too glitchy, I can just make the view farther out.
      // graphRef.current.zoomToFit(1000, 10, (n) => {
      //   console.log(highlightedNodes.current.includes(n.pos))
      //   return highlightedNodes.current.includes(n.pos) // || hoverHighlightedNodes.current.includes(n.pos)
      // })

      graphRef.current.cameraPosition(
          newPos,
          node,
          1000,
      );

      nodeColorWrapper(showSelectedHighlightedNodes)
      nodeColorStyle()
    } else {
      // shift click
      if (multiselectNodes.current.includes(node.pos)) {
        multiselectNodes.current.splice(multiselectNodes.current.indexOf(node.pos), 1)
      } else {
        multiselectNodes.current.push(node.pos)
      }
      setNodeDataShowing(true)
      setNumberMultiselect(multiselectNodes.current.length)
      setActiveMultiselect(multiselectNodes.current.length ? true : false)
      nodeColorWrapper(showSelectedHighlightedNodes)
      nodeColorStyle()
    }
  }

  function stopMovement() {
    graphControls.current.noRotate = true
  }

  function startMovement() {
    graphControls.current.noRotate = false
  }

  function initializeMultiselectListeners() {
    // Tried to switch to using state variables and it just stopped working
    let selectionBox = undefined
    let cameraPos = undefined
    let boxSelect = undefined
    let boxSelectStart = undefined

    document.getElementById('graph').addEventListener('pointerdown', (e) => {
      if (e.shiftKey) {
        e.preventDefault();
        stopMovement()
        boxSelect = document.createElement('div');
        boxSelect.id = 'boxSelect';
        boxSelect.style.left = e.offsetX.toString() + 'px';
        boxSelect.style.top = e.offsetY.toString() + 'px';
        boxSelectStart = {
          x: e.offsetX,
          y: e.offsetY,
        };
        // app element is the element just above the forceGraph element.
        document.getElementById('vkg').appendChild(boxSelect);
        // utility to convert between 2d select box coordinates and 3d graph coordinates
        selectionBox = new SelectionBox(graphRef.current.camera(), graphRef.current.scene());
        // window <-> graph-coords translation
        selectionBox.startPoint.set(
            (e.clientX / window.innerWidth) * 2 - 1,
            - (e.clientY / window.innerHeight) * 2 + 1,
            0.5,
        );
        // save camera position
        cameraPos = graphRef.current.cameraPosition();
      }
    });

    document.getElementById('graph').addEventListener('pointermove', (e) => {
      if (e.shiftKey && boxSelect) {
        e.preventDefault();
        if (e.offsetX < boxSelectStart.x) {
          boxSelect.style.left = e.offsetX.toString() + 'px';
          boxSelect.style.width = (boxSelectStart.x - e.offsetX).toString() + 'px';
        } else {
          boxSelect.style.left = boxSelectStart.x.toString() + 'px';
          boxSelect.style.width = (e.offsetX - boxSelectStart.x).toString() + 'px';
        }
        if (e.offsetY < boxSelectStart.y) {
          boxSelect.style.top = e.offsetY.toString() + 'px';
          boxSelect.style.height = (boxSelectStart.y - e.offsetY).toString() + 'px';
        } else {
          boxSelect.style.top = boxSelectStart.y.toString() + 'px';
          boxSelect.style.height = (e.offsetY - boxSelectStart.y).toString() + 'px';
        }
        // window <-> graph-coords translation
        selectionBox.endPoint.set(
            (e.clientX / window.innerWidth) * 2 - 1,
            - (e.clientY / window.innerHeight) * 2 + 1,
            0.5,
        );
        graphRef.current.cameraPosition(cameraPos);
      } else if (boxSelect) {
        boxSelect.remove();
      }
    });

    document.getElementById('graph').addEventListener('pointerup', (e) => {
      if (e.shiftKey && boxSelect) {
        props.storePayload({
          isLoading: true,
        })
        startMovement()
        e.preventDefault();
        boxSelect.remove();
        // window <-> graph-coords translation
        selectionBox.endPoint.set(
            (e.clientX / window.innerWidth) * 2 - 1,
            - (e.clientY / window.innerHeight) * 2 + 1,
            0.5,
        );
        // set selected nodes
        // (these accessors may be specific to how I put nodes together...)
        const selectedNodes = selectionBox.select()
            .filter((item) => item.__graphObjType === 'node')
            .map((item) => item.__data);
        // reset camera position var
        cameraPos = undefined;
        for (let sNIndex = 0; sNIndex < selectedNodes.length; sNIndex++) {
          const position = selectedNodes[sNIndex]?.pos
          if (position !== undefined && !multiselectNodes.current.includes(position)) {
            multiselectNodes.current.push(selectedNodes[sNIndex].pos)
          }
        }
        props.storePayload({
          isLoading: false,
        })
        setNodeDataShowing(true)
        setNumberMultiselect(multiselectNodes.current.length)
        setActiveMultiselect(multiselectNodes.current.length ? true : false)
        nodeColorWrapper(showSelectedHighlightedNodes)
        nodeColorStyle()
      } else if (boxSelect) {
        boxSelect.remove();
        startMovement()
      }
    });
  }

  useEffect(() => {
    if (selectedNode.current || _coordinates) {
      if (limitedViewRef.current) {
        limitedView()
      } else {
        fullView()
      }
    }
  }, [_limitedView])

  // firebase data formatting into graph
  useEffect(() => {
    if (props.nodes && Object.keys(props.nodes).length) {
      const data = dataFormatRedis(props.nodes)
      fullNodesRef.current = data;
      graphRef?.current?.graphData({
        'nodes': data,
        'links': [],
      })
      if (activeSearchRef.current) {
        limitedView()
      }
    } else { // Remove graph nodes if props.nodes is null
      graphRef?.current?.graphData({
        'nodes': [],
        'links': [],
      })
      // Reset node and search states
      setNodeDataShowing(false)
      setNodeDataExpanded(false)
      setActiveSearch(null)
      setSearchString(null)
      clearSearchText()
      clearSelection()
    }
  }, [props.nodes])

  // resizing graph to make reactive
  useEffect(() => {
    const sizeObserver = new ResizeObserver((entries) => {
      if (graphRef.current) {
        graphRef.current.height(entries[0].contentBoxSize[0].blockSize)
        graphRef.current.width(entries[0].contentBoxSize[0].inlineSize)
      }
    })
    if (document.getElementById('graph')) {
      sizeObserver.observe(document.getElementById('graph'))
      initializeMultiselectListeners()
    }
  }, [])

  useEffect(() => {
    setIsFilterShowing(false)
    clearNodeDataShowing(false)
    if (props.selectedGraphId) {
      setNodeTabState('text')
      clearPress()
    }
  }, [props.selectedGraphId])

  // View Functions

  function limitedView() {
    const closeNodes = []
    if (!activeSearchRef.current) {
      if (selectedNode.current) {
        closeNodes.push(fullNodesRef.current[selectedNode.current])
      }
      for (let i = 0; i < fullNodesRef.current.length; i++) {
        const dist = selectedNode.current ?
            distanceToSelectedNode(fullNodesRef.current[i]) :
            distanceToHoverNode({x: _coordinates[0], y: _coordinates[1], z: _coordinates[2]}, fullNodesRef.current[i])
        if (selectedNode.current) {
          if (i !== selectedNode.current.pos && dist < 50) {
            closeNodes.push(fullNodesRef.current[i])
          }
        } else {
          if (dist < 50) {
            closeNodes.push(fullNodesRef.current[i])
          }
        }
      }
      if (selectedCoordinates.current) {
        zoomTo(selectedCoordinates.current[0], selectedCoordinates.current[1], selectedCoordinates.current[2])
      }
    } else {
      for (let i = 0; i < fullNodesRef.current.length; i++) {
        if (highlightedNodes.current.includes(fullNodesRef.current[i].pos)) {
          closeNodes.push(fullNodesRef.current[i])
        }
      }
    }
    graphRef.current.graphData({nodes: closeNodes, links: []})
    setTimeout(() => {
      nodeColorStyle()
    }, 1)
    // the 1ms delay is so that the default force graph color function doesn't overwrite the nodes
    nodeColorWrapper(showSelectedHighlightedNodes)
  }

  function fullView() {
    const data = dataFormatRedis(props.nodes)
    fullNodesRef.current = data;
    graphRef.current.graphData({
      'nodes': data,
      'links': [],
    })
    centerView()
    graphRef.current.nodeOpacity(1)
    if (visualRef.current) {
      renderFilterObjects()
    }
    setTimeout(() => {
      // the 1ms delay is so that the default force graph color function doesn't overwrite the nodes
      nodeColorStyle()
      nodeColorWrapper(showSelectedHighlightedNodes)
    }, 1)
  }

  function centerView() {
    graphRef.current.zoomToFit(1000, 0, (n) => {
      return true
    })
  }

  function isCenteredAtSearch() {
    return _coordinates && _coordinates[0] === searchCoordinatesRef.current[0] &&
      _coordinates[1] === searchCoordinatesRef.current[1] &&
      _coordinates[2] === searchCoordinatesRef.current[2]
  }

  function getFullState() {
    if (!_limitedView && !isCenteredAtSearch()) {
      return 'selected-icon'
    }
    return ''
  }

  function getSingleState() {
    if (selectedNode.current === null) {
      return 'disabled-icon'
    }
    if (_limitedView) {
      return 'selected-icon'
    }
    return ''
  }

  function getSearchState() {
    if (!activeSearch) {
      return 'disabled-icon'
    } else if (activeSearch && !selectedNode.current) {
      return 'selected-icon'
    }
    return ''
  }

  function setFullState(e) {
    if (getFullState() !== 'disabled') {
      setLimitedView(false)
      fullView()
    }
  }

  function setSingleState(e) {
    if (getSingleState() !== 'disabled') {
      limitedView()
      setLimitedView(true)
    }
  }

  function setSearchState(e) {
    if (getSearchState() !== 'disabled') {
      setSelection(null, null, null)
      setSelectedNode(null)
      setTextValue(null)
      limitedView()
      setCoordinates(searchCoordinatesRef.current, 1000)
    }
  }

  // nodePoss are positions to remove from search results
  function removeMultiselectFromSearch(nodePoss) {
    updateHighlightsAfterDelete(nodePoss[0], nodePoss)
  }

  function clearMultiselect(e) {
    setActiveMultiselect(false)
    setNumberMultiselect(0)
    multiselectNodes.current = []
    if (!activeSearch) {
      highlightedNodes.current = []
      setSelectedNode(null)
    }
    setNodeDataShowing(false)
  }

  function openMultiselect(e) {
    const multiselectNodesArray = multiselectNodes.current.map((pos) => fullNodesRef.current[pos])
    props.storePayload({
      isModal: true,
      isModalShowing: true,
      modalMode: 'node-multi',
      multiselectNodes: multiselectNodesArray,
    })
  }


  // Action / Button Functions

  function distanceCompareFunction(center, a_pos, b_pos) {
    const a_node = fullNodesRef.current[a_pos]
    const a_coor = [a_node.x, a_node.y, a_node.z]
    const b_node = fullNodesRef.current[b_pos]
    const b_coor = [b_node.x, b_node.y, b_node.z]
    const a_dist = arrayDistance(center, a_coor)
    const b_dist = arrayDistance(center, b_coor)
    return a_dist - b_dist
  }

  async function submitSearch(searchString) {
    clearSelection()
    let filteredMetadata = {}
    if (filterRef.current) {
      filteredMetadata = Object.assign({}, ...filterRef.current.map((filterObj) => {
        const retObj = {}
        retObj[filterObj['key']] = filterObj['value']
        if (filterObj['type'] === 'list') {
          retObj[filterObj['key']] = {
            'oneOf': Object.keys(filterObj['value']).map((valKey) => {
              return filterObj['value'][valKey]
            }),
          }
        } else if (filterObj['type'] === 'range') {
          if (/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(filterObj['value']['range']['from'])) {
            retObj[filterObj['key']] = {
              'moreThanEq': filterObj['value']['range']['from'],
              'lessThanEq': filterObj['value']['range']['to'],
            }
          } else {
            retObj[filterObj['key']] = {
              'moreThanEq': parseFloat(filterObj['value']['range']['from']),
              'lessThanEq': parseFloat(filterObj['value']['range']['to']),
            }
          }
        }
        return retObj
      }))
    }
    const searchResult = await props.searchGraph(
        props.database,
        props.selectedGraphId,
        searchString,
        (filterRef.current && Object.keys(filteredMetadata).length) ? filteredMetadata : null,
    )
    if (searchResult?.status === 'SUCCESS') {
      const hLNodes = searchResult?.queryResults?.[0]?.['results'] || []
      if (hLNodes.length > 0) {
        let minX = 0
        let minY = 0
        let minZ = 0
        let maxX = 0
        let maxY = 0
        let maxZ = 0
        const newHighlights = []
        const searchNodes = []
        const hLNodesVals = hLNodes.map((node) => node[Object.keys(node)[0]].text)
        for (let i = 0; i < fullNodesRef.current.length; i++) {
          if (hLNodesVals.includes(fullNodesRef.current[i].id)) {
            minX = fullNodesRef.current[i].x < minX ? fullNodesRef.current[i].x : minX
            minY = fullNodesRef.current[i].y < minY ? fullNodesRef.current[i].y : minY
            minZ = fullNodesRef.current[i].z < minZ ? fullNodesRef.current[i].z : minZ
            maxX = fullNodesRef.current[i].x > maxX ? fullNodesRef.current[i].x : maxX
            maxY = fullNodesRef.current[i].y > maxY ? fullNodesRef.current[i].y : maxY
            maxZ = fullNodesRef.current[i].z > maxZ ? fullNodesRef.current[i].z : maxZ
            newHighlights.push(fullNodesRef.current[i].pos)
            searchNodes.push(fullNodesRef.current[i])
          }
        }
        searchNodes.sort((a_node, b_node) => {
          return hLNodesVals.indexOf(a_node.id) - hLNodesVals.indexOf(b_node.id)
        })
        props.storePayload({'searchNodes': searchNodes, 'searchString': searchString})
        const coor = [(minX + maxX)/2, (minY + maxY)/2, (minZ + maxZ)/2]
        newHighlights.sort((a_pos, b_pos) => {
          return distanceCompareFunction(coor, a_pos, b_pos)
        })
        const farthestNode = fullNodesRef.current[newHighlights[newHighlights.length - 1]]
        const farthestCoor = [farthestNode.x, farthestNode.y, farthestNode.z]
        newHighlights.sort((a_pos, b_pos) => {
          return distanceCompareFunction(farthestCoor, a_pos, b_pos)
        })
        setActiveSearch(searchString)
        selectedNode.current = null
        highlightedNodes.current = newHighlights
        if (graphRef.current) {
          nodeColorWrapper(showSelectedHighlightedNodes)
        }
        setCoordinates(coor)
        searchCoordinatesRef.current = coor
        zoomTo(coor[0], coor[1], coor[2], 1000)
        setLimitedView(true)
      } else {
        props.storePayload({
          userMessage: 'No nodes returned is search request. The search filters or search size might need to be updated.',
          notificationType: 2,
          notificationIcon: 'warning',
          isNotificationVisible: true,
        })
      }
    } else {
      const errorMessage = `Search failure${searchResult?.error?.message ? ':\n' + searchResult?.error?.message + '\n\nError Information:\n' + JSON.stringify(searchResult?.error, null, 2) : '.'}`
      props.storePayload({
        userMessage: errorMessage,
        notificationType: 1,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      })
    }
  }

  function inlineSearchWrapper() {
    submitSearch(searchString)
  }

  function openSearchList(e) {
    e.preventDefault()
    props.storePayload({
      isModal: true,
      isModalShowing: true,
      modalMode: 'node-search',
    })
  }

  function openMenu(e) {
    e.preventDefault()
    props.storePayload({
      isModal: true,
      isModalShowing: true,
      modalMode: 'menu',
    })
  }

  function clearPress() {
    setNodeDataShowing(false)
    setNodeDataExpanded(false)
    setActiveSearch(null)
    setSearchString(null)
    clearSearchText()
    clearSelection()
    centerView()
    graphRef.current.graphData({
      'nodes': fullNodesRef.current,
      'links': [],
    })
    nodeColorWrapper(showSelectedHighlightedNodes)
    nodeColorStyle()
    // centerView()
  }

  function clearNodeDataShowing(e) {
    setNodeDataShowing(false)
    setNodeDataExpanded(false)
  }

  function toggleExpand() {
    setNodeDataExpanded(!nodeDataExpanded)
  }

  function clearSearchText() {
    setSearchString(null)
    const searchEl = document.getElementById('search-container')
    if (searchEl) {
      searchEl.getElementsByTagName('input')[0].value = null
    }
  }

  function clearSearch(e) {
    clearSearchText()
    if (activeSearchRef.current) {
      clearSelection()
      centerView()
      graphRef.current.graphData({
        'nodes': fullNodesRef.current,
        'links': [],
      })
      // graphRef.current.nodeColor((n) => {
      //   n.fx = n.x
      //   n.fy = n.y
      //   n.fz = n.z
      //   return DEFAULT_COLOR
      // })
      setTimeout(() => {
        nodeColorStyle()
        nodeColorWrapper(showSelectedHighlightedNodes)
      }, 1)
    }
    setActiveSearch(null)
  }

  // Selection Functions
  function clearSelection() {
    setLimitedView(false)
    setSelection(null, null, null)
    setSelectedNode(null)
    setTextValue(null)
    setCoordinates(null)
    highlightedNodes.current = []
    hoverHighlightedNodes.current = []
  }

  function setSelection(val, coor, id) {
    setTextValue(val)
    setCoordinates(coor)
    setNodeId(id)
  }

  function resetGraphView() {
    clearSelection()
    centerView()
  }

  function iterateSearchResults(difference) {
    const highlightIndex = highlightedNodes.current.indexOf(selectedNode.current)
    const newHLIndex = (highlightIndex + difference + highlightedNodes.current.length) % highlightedNodes.current.length
    const newPos = highlightedNodes.current[newHLIndex]
    nodeClick(fullNodesRef.current[newPos], {})
  }

  function updateHighlightsAfterDelete(deleted_pos, all_delete_poss=null) {
    const deletePoss = all_delete_poss ? all_delete_poss : [deleted_pos]
    deletePoss.sort((a, b) => a - b)
    fullNodesRef.current = fullNodesRef.current.filter((nodeObj) => !deletePoss.includes(nodeObj.pos)).map((nodeObj) => {
      let posDelta = 0
      for (let delIndex = 0; delIndex < deletePoss.length; delIndex++) {
        if (nodeObj.pos > deletePoss[delIndex]) {
          posDelta++
        }
      }
      nodeObj.pos = nodeObj.pos - posDelta
      return nodeObj
    })
    if (highlightedNodes.current) {
      highlightedNodes.current = highlightedNodes.current.filter((pos) => !deletePoss.includes(pos)).map((pos) => {
        let posDelta = 0
        for (let delIndex = 0; delIndex < deletePoss.length; delIndex++) {
          if (pos > deletePoss[delIndex]) {
            posDelta++
          }
        }
        const newPos = pos - posDelta
        return newPos
      })
      const newSearchNodes = highlightedNodes.current.map((pos) => fullNodesRef.current[pos])
      props.storePayload({
        searchNodes: JSON.parse(JSON.stringify(newSearchNodes)),
      })
    }
    if (selectedNode.current) {
      selectedNode.current = selectedNode.current <= deleted_pos ? selectedNode.current : selectedNode.current - 1
    }
    if (hoverHighlightedNodes.current) {
      hoverHighlightedNodes.current = hoverHighlightedNodes.current.filter((pos) => !deletePoss.includes(pos)).map((pos) => pos < deleted_pos ? pos : pos - 1)
    }
    if (multiselectNodes.current) {
      multiselectNodes.current = multiselectNodes.current.filter((pos) => !deletePoss.includes(pos)).map((pos) => {
        let posDelta = 0
        for (let delIndex = 0; delIndex < deletePoss.length; delIndex++) {
          if (pos > deletePoss[delIndex]) {
            posDelta++
          }
        }
        const newPos = pos - posDelta
        return newPos
      })
      const newMultiselectNodes = multiselectNodes.current.map((pos) => fullNodesRef.current[pos])
      props.storePayload({
        multiselectNodes: JSON.parse(JSON.stringify(newMultiselectNodes)),
      })
      setNumberMultiselect(multiselectNodes.current.length)
      setActiveMultiselect(multiselectNodes.current.length ? true : false)
      nodeColorWrapper(showSelectedHighlightedNodes)
    }
  }

  // Camera Util Function(s)
  function zoomTo(x, y, z, dist) {
    const distance = dist ? dist : 40;
    const distRatio = 1 + distance/Math.hypot(x, y, z);
    const newPos = x || y || z ?
    {x: x * distRatio, y: y * distRatio, z: z * distRatio} :
    {x: 0, y: 0, z: distance}; // special case if node is in (0,0,0)
    graphRef.current.cameraPosition(
        newPos,
        {x: x, y: y, z: z},
        1000,
    )
    selectedCoordinates.current = [x, y, z]
  }

  function zoomMath(zoomIn) {
    const cameraPosition = graphRef.current.camera().position
    const currentDistance = distanceToSelectedNode(cameraPosition)
    if (!zoomIn || currentDistance > 5) { // either zooming out or zooming in and not already super close
      // calculate unit vector
      const deltaX = cameraPosition.x - selectedCoordinates.current[0]
      const deltaY = cameraPosition.y - selectedCoordinates.current[1]
      const deltaZ = cameraPosition.z - selectedCoordinates.current[2]
      const uDX = deltaX / currentDistance
      const uDY = deltaY / currentDistance
      const uDZ = deltaZ / currentDistance
      // calculate the difference in position
      let zoomDifference = (currentDistance > 1000) ?
                                500 :
                                (currentDistance < 5) ?
                                0 :
                                currentDistance / 2
      if (!zoomIn) {
        zoomDifference = (currentDistance > 200) ? 200 : currentDistance * 2
      }
      // calculcate the direction
      const direction = (zoomIn) ? -1 : 1
      // set new position
      const newPos = {
        x: cameraPosition.x + uDX * zoomDifference * direction,
        y: cameraPosition.y + uDY * zoomDifference * direction,
        z: cameraPosition.z + uDZ * zoomDifference * direction,
      }
      const lookAt = {
        x: selectedCoordinates.current[0],
        y: selectedCoordinates.current[1],
        z: selectedCoordinates.current[2],
      }
      graphRef.current.cameraPosition(newPos, lookAt, 1000)
    }
  }

  // DOM Formatting Functions
  function roundCoordinate(coor) {
    return Math.round(coor * 10**6) / 10**6
  }

  function getTabContent(hasMetadata) {
    const content = {
      'text': {
        title: 'Description',
        isDisabled: false,
        render: (
          <div className='container-wrapper modified-container-wrapper'>
            <textarea
              className={`node-content ${editMode ? 'node-edit': ''}`}
              id='node-content'
              value={updatedValue}
              disabled={!editMode}
              onChange={
                (e) => {
                  setUpdatedValue(e.target.value)
                }
              }
            >
            </textarea>
          </div>
        ),
      },
      'meta': {
        title: 'Metadata',
        isDisabled: false,
        render: (
          <div className='container-wrapper modified-container-wrapper metadata-container-wrapper'>
            <MetadataTable
              canEdit={editMode}
              metadataState={_selectedNodeObject?.metadata || []}
              initialMetadataState={selectedNodeObjectRef.current?.metadata || []}
              onMetadataEdit={onMetadataEdit}
            />
          </div>
        ),
      },
    }
    if (!hasMetadata) {
      delete content['meta']
    }
    return content
  }

  function onMetadataEdit(metadataKey, newValue) {
    const nodeObj = JSON.parse(JSON.stringify(_selectedNodeObject))
    nodeObj.metadata[metadataKey] = newValue
    _setSelectedNodeObject(nodeObj)
  }

  // Return

  return (
    <div className='graph-side'>
      <div className='graph-main'>
        <div className='graph-main-body'>
          {props.metadataValues && isFilterShowing && <Filter />}
          <div className="graph-view" id="graph"></div>
          <div id='stats'></div>
          <div className='search-bottom unselectable'>
            {/* <textarea
              onChange={(e) => {
                setSearchString(e.target.value)
              }}
            />
            <button onClick={clickSearch}>
              <img alt='' src={Search}/>
            </button> */}
            { activeSearch &&
              <div className='search-arrows-row'>
                <div
                  className='search-arrow'
                  key='search-arrow-left'
                  onClick={(e) => {
                    iterateSearchResults(-1)
                  }}
                >
                  <Icon
                    icon={'arrow-ios-back-outline'}
                    key={'search-left'}
                    className='cursor-pointer'
                  />
                </div>
                <div
                  className='search-arrow'
                  key='search-arrow-right'
                  onClick={(e) => {
                    iterateSearchResults(1)
                  }}
                >
                  <Icon
                    icon={'arrow-ios-forward-outline'}
                    key={'search-right'}
                    className='cursor-pointer'
                  />
                </div>
              </div>
            }
            <div className='coordinate-row'>
              {_coordinates && _coordinates.map((coor, i) => {
                return (
                  <div
                    className='coordinate-box'
                    key={`${String.fromCharCode(88+i)}_${roundCoordinate(coor)}`}
                  >
                    <p>{`${String.fromCharCode(88+i)}: ${roundCoordinate(coor)}`}</p>
                  </div>
                )
              })}
            </div>
          </div>
          <div
            className='chat-link-btn'
          >
            <Button
              type={6}
              text={<div className='chat-link-contents'>
                <Icon
                  icon='message-square-outline'
                />
                <span>Chat</span>
                <Icon
                  icon='external-link-outline'
                />
              </div>}
              theme={'dark'}
              onClick={() => {
                Helpers.openLinkInNewTab(`${process.env.REACT_APP_CHAT_UI}?vkgId=${props.selectedGraphId}`)
              }}
              iconPosition='left'
              iconJustify='center'
            />
          </div>
          <div
            className={`center-designs ${(!props.isLoading && !hoverText) ? 'center-fade-in': 'center-fade-out'}`}
          >
            <div
              className='center-designs-contents'
            >
              <img
                src={Frame}
                alt=''
                className='frame'
              />
              <img
                src={Confidence}
                alt=''
              />
              <img
                src={Indices}
                alt=''
              />
              <img
                src={Pointer}
                alt=''
                style={{transform: `rotate(${(zoomVal) * 225/100 - 90}deg)`}}
              />
              {_selectedNode !== null &&
              <>
                <img
                  src={CrossHairs}
                  alt=''
                  className={`cross-hair`}
                />
              </>}
              {_selectedNode === null && activeSearch !== null &&
              <>
                <img
                  src={CrossHairs}
                  alt=''
                  className={`cross-hair search-cross-hair`}
                />
              </>}
            </div>
          </div>
          {/* {hoverText &&
          <div className='hover-text-col'>
            <div className='hover-text-content'>
              <div className='hover-text-header'>
                <span>Hover Data Preview</span>
                <Icon
                  icon={'close-outline'}
                  key={'close-preview'}
                  onClick={(e) => {
                    setHoverText(null)
                  }}
                  className='cursor-pointer'
                />
              </div>
              <div className='hover-text-body graph-list-scroll-bar'>
                {hoverTextRef.current}
              </div>
            </div>
          </div>
          } */}
          <Modal
            resetGraphView={resetGraphView}
            clearMultiselect={clearMultiselect}
            removeMultiselectFromSearch={removeMultiselectFromSearch}
            clearSearch={clearSearch}
          />
        </div>
        {nodeDataShowing &&
          <div className='graph-main-node'>
            <div className={`node-view animate-node-view ${nodeDataExpanded ? 'node-view-expanded': ''}`}>
              <div className='node-view-content'>
                <div className='node-header'>
                  <h1 className=''>{activeMultiselect ? `Selected Nodes (${numberMultiselect - multiPreviewUnselectCount} / ${numberMultiselect})`: 'Node Data'}</h1>
                  {/* <div className='menu'>
                    <div className='menu-content'>
                      <img
                        src={More}
                        alt=''
                        onClick={() => {
                          setIsNodeViewDropdown(!isNodeViewDropdown)
                        }}
                      />
                      {isNodeViewDropdown &&
                        <div className='menu-dropdown'>
                          <div className='dropdown-item'>
                            <img
                              src={Delete}
                              alt=''
                              className='dropdown-item-icon'
                            />
                            <p
                              className='dropdown-item-text'
                            >
                              Delete
                            </p>
                          </div>
                        </div>
                      }
                    </div>
                  </div> */}
                  <div
                    className={'icon-container'}
                  >
                    <Icon
                      onClick={clearNodeDataShowing}
                      theme={'dark'}
                      icon='close-outline'
                      iconId={'node-data-close'}
                    />
                    <Icon
                      onClick={toggleExpand}
                      theme={'dark'}
                      key={nodeDataExpanded ? 'collapse-outline': 'expand-outline'}
                      icon={nodeDataExpanded ? 'collapse-outline': 'expand-outline'}
                    />
                  </div>
                </div>
                <div className={`node-body ${activeMultiselect ? 'node-body-multi': ''}`}>
                  {activeMultiselect ?
                    <Fragment key={`multi-${numberMultiselect}`}>
                      {multiselectNodes.current.map((pos, index) => {
                        const node = fullNodesRef.current[pos]
                        return (
                          <MultiselectPreview
                            key={'preview-'+pos}
                            node={node}
                            index={index}
                            numberMultiselect={numberMultiselect}
                            isUnselected={multiPreviewUnselect.includes(pos)}
                            updateMultiUnselect={(unselected) => {
                              if (!unselected) {
                                multiPreviewUnselect.splice(multiPreviewUnselect.indexOf(pos), 1)
                                setMultiPreviewUnselectCount(multiPreviewUnselectCount - 1)
                              } else {
                                multiPreviewUnselect.push(pos)
                                setMultiPreviewUnselectCount(multiPreviewUnselectCount + 1)
                              }
                              setMultiPreviewUnselect(multiPreviewUnselect)
                            }}
                          />
                        )
                      })}
                    </Fragment> :
                    <TabContainer
                      theme={'dark'}
                      className={'tab-container-modifications'}
                      tabContent={getTabContent(props.hasMetadata)}
                      activeTab={nodeTabState}
                      style={{height: '100%'}}
                      canChangeTab={() => true}
                      onTabChange={(tabName) => {
                        setNodeTabState(tabName)
                      }}
                    />
                  }
                </div>
                {activeMultiselect ?
                <div
                  className='node-buttons'
                >
                  <Button
                    theme={'dark'}
                    text={'Next'}
                    onClick={(e) => {
                      multiselectNodes.current = multiselectNodes.current.filter((val) => {
                        return !multiPreviewUnselect.includes(val)
                      })
                      removeMultiselectFromSearch(multiPreviewUnselect)
                      setMultiPreviewUnselect([])
                      setMultiPreviewUnselectCount(0)
                      if (multiselectNodes.current.length) {
                        openMultiselect(e)
                      } else {
                        setNodeDataShowing(false)
                      }
                    }}
                  />
                </div> :
                <div
                  className='node-buttons'
                >
                  {!editMode && <div className='editted-buttons'>
                    {confirmDelete &&
                    <Button
                      type={6}
                      text={'Back'}
                      theme={'dark'}
                      width={'calc(50% - 4px)'}
                      style={confirmDelete ? {marginRight: '8px'} : {}}
                      onClick={(e) => {
                        setUpdatedValue(_textValue)
                        setEditMode(false)
                        setConfirmDelete(false)
                      }}
                    />}
                    <Button
                      type={confirmDelete ? 5 : 10}
                      text={confirmDelete ? 'Confirm Delete' : 'Delete'}
                      theme={'dark'}
                      width={confirmDelete ? 'calc(50% - 4px)' : 'calc(100%)'}
                      onClick={(e) => {
                        if (confirmDelete) {
                          props.deleteNode(props.database, props.selectedGraphId, nodeId)
                          setConfirmDelete(false)
                          setNodeDataShowing(false)
                          if (activeSearch) {
                            updateHighlightsAfterDelete(selectedNode.current)
                            iterateSearchResults(-1)
                            limitedView()
                          } else {
                            clearSelection()
                            setLimitedView(false)
                            fullView()
                          }
                        } else {
                          setConfirmDelete(true)
                        }
                      }}
                      disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                    />
                  </div>}
                  {!confirmDelete &&
                  <>
                    {editMode ?
                    <div className='editted-buttons'>
                      <Button
                        type={6}
                        text={'Back'}
                        theme={'dark'}
                        width={'calc(50% - 4px)'}
                        style={{marginRight: '8px'}}
                        onClick={(e) => {
                          setUpdatedValue(_textValue)
                          setEditMode(false)
                          setConfirmDelete(false)
                          setSelectedNodeObject(selectedNodeObjectRef.current)
                        }}
                      />
                      <Button
                        type={1}
                        text={'Save'}
                        theme={'dark'}
                        width={'calc(50% - 4px)'}
                        onClick={(e) => {
                          _setTextValue(updatedValue)
                          setSelectedNodeObject(_selectedNodeObject)
                          setEditMode(false)
                          setConfirmDelete(false)
                          props.updateNode(props.database, props.selectedGraphId, nodeId, updatedValue, _selectedNodeObject?.metadata)
                          // might want to make update/delete return promises and do the error handling in the Component
                        }}
                        disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                      />
                    </div> :
                    <Button
                      type={1}
                      text={'Edit'}
                      theme={'dark'}
                      width={'calc(100%)'}
                      onClick={(e) => {
                        setEditMode(true)
                        setTimeout(() => {
                          if (nodeTabState === 'text') {
                            document.getElementById('node-content').focus();
                          }
                        }, 0) // this is needed
                        setConfirmDelete(false)
                      }}
                      disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                    />
                    }
                  </>}
                </div>}
              </div>
            </div>
          </div>
        }
      </div>
      <div
        className='tool-bar-parent'
      >
        <div
          className='tool-bar'
        >
          <div className='tool-left'>
            <div
              className='has-title-right icon-wrapper'
              title='Menu'
            >
              <Icon
                icon='menu-outline'
                onClick={openMenu}
              />
            </div>
            <span className='tool-bar-title'>{props.selectedGraphId}</span>
            <div className='tool-bar-divider' />
            <Settings />
            {props.metadataValues &&
              <div
                className='has-title icon-wrapper'
                title='Filters'
              >
                <Icon
                  icon='options-2-outline'
                  className={isFilterShowing? 'selected-icon': ''}
                  onClick={() => {
                    setIsFilterShowing(!isFilterShowing)
                  }}
                />
              </div>
            }
          </div>
          <div className='tool-search' id='search-container'>
            <SearchBar
              isCollapsed={false}
              isCollapsible={false}
              onChange={(e) => {
                setSearchString(e.target.value)
              }}
              autocomplete='off'
              theme='dark'
              onSubmit={inlineSearchWrapper}
              style={{height: '100%', width: '100%'}}
              formId={activeSearch && props.metadataValues && Helpers.getActiveFilters(props.activeFilters).length > 0 ?
                'full-search-options' : activeSearch ?
                'only-active-search-options' : (props.metadataValues && Helpers.getActiveFilters(props.activeFilters).length > 0) ?
                'only-metadata-options' : 'no-options'
              }
              inputId={'graph-search-input'}
            />
            <div
              className='active-search-wrapper'
            >
              {activeSearch &&
                <div
                  className='has-title icon-wrapper'
                  title='Clear Search'
                >
                  <Icon
                    icon='close-outline'
                    className='clear-icon'
                    onClick={(e) => {
                      e.stopPropagation()
                      clearSearch(e)
                    }}
                  />
                </div>
              }
              {activeSearch && props.metadataValues && Helpers.getActiveFilters(props.activeFilters).length > 0 &&
                <div className='tool-bar-divider' />
              }
              {props.metadataValues && Helpers.getActiveFilters(props.activeFilters).length > 0 &&
                <Switch
                  label={`Apply ${Helpers.getActiveFilters(props.activeFilters).length} Filters`}
                  reversed={true}
                  checked={props.isFilterSearch}
                  onChange={(e) => {
                    props.storePayload({
                      isFilterSearch: e.target.checked,
                    })
                  }}
                  theme={props.theme}
                />
              }
            </div>
            <div
              className='has-title icon-wrapper'
              title='Open Search'
            >
              <Icon
                icon='list-outline'
                className='search-icon'
                onClick={(e) => {
                  if (activeSearch) {
                    openSearchList(e)
                  }
                }}
              />
            </div>
          </div>
          <div
            className='tool-right'
          >
            <div className='tool-bar-divider' />
            <div
              className='has-title icon-wrapper'
              title='Full View'
            >
              <Icon
                icon='globe-outline'
                key={`full-${getFullState()}`}
                className={getFullState()}
                onClick={() => {
                  if (getFullState() !== 'disabled-icon') {
                    setFullState()
                  }
                }}
              />
            </div>
            <div
              className='has-title icon-wrapper'
              title='Search View'
            >
              <Icon
                icon='loader-outline'
                key={`search-${getSearchState()}`}
                className={getSearchState()}
                onClick={() => {
                  if (getSearchState() !== 'disabled-icon') {
                    setSearchState()
                  }
                }}
              />
            </div>
            <div
              className={`has-title${activeMultiselect ? '': '-right'} icon-wrapper`}
              title='Single View'
            >
              <Icon
                icon='radio-button-on-outline'
                key={`single-${getSingleState()}`}
                className={getSingleState()}
                onClick={(e) => {
                  if (getSingleState() === 'selected-icon' && !activeSearch) {
                    clearPress()
                  } else if (getSingleState() !== 'disabled-icon') {
                    setSingleState()
                  }
                }}
              />
            </div>
            {activeMultiselect &&
              <>
                <div className='tool-bar-divider' />
                <span>{numberMultiselect} Nodes</span>
                <div
                  className='has-title-right icon-wrapper'
                  title='Open Multiselect'
                >
                  <Icon
                    key={'multi-select'}
                    icon={'arrow-circle-up-outline'}
                    onClick={openMultiselect}
                  />
                </div>
                <div
                  className='has-title-right icon-wrapper'
                  title='Clear Multiselect'
                >
                  <Icon
                    key={'multi-select'}
                    icon={'close-circle-outline'}
                    onClick={clearMultiselect}
                  />
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  nodes: state.userReducer.nodes,
  isLoading: state.userReducer.isLoading,
  isGettingNodes: state.userReducer.isGettingNodes,
  graphList: state.userReducer.graphList,
  selectedGraphId: state.userReducer.selectedGraphId,
  vkgDomain: state.userReducer.vkgDomain,
  searchNodes: state.userReducer.searchNodes,
  hasMetadata: state.userReducer.hasMetadata,
  isFilterOn: state.userReducer.isFilterOn,
  metadata: state.userReducer.metadata,
  metadataValues: state.userReducer.metadataValues,
  activeFilters: state.userReducer.activeFilters,
  visualizations: state.userReducer.visualizations,
  theme: state.userReducer.theme,
  isFilterSearch: state.userReducer.isFilterSearch,
  userData: state.userReducer.userData,
})

export default connect(
    mapStateToProps,
    {storePayload, getNodes, searchGraph,
      getGraphs, deleteNode, updateNode},
)(Graph)
