/* eslint-disable max-len */
import Helpers from '../Helpers';
import types from '../types'

// import {getNodes} from './getNodes.js'
import {getGraphs} from './getGraphs.js'

export const removeDuplicates = (database) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, selectedGraphId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[selectedGraphId]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + '/remove_duplicates_vkg'
    // TODO: this doesn't exist
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchPostJson(endpoint, headers, {'vkgid': selectedGraphId})
        .then((res) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Successfully removed the duplicates',
              notificationType: 2,
              notificationIcon: 'check',
              isNotificationVisible: true,
            },
          })
          dispatch(getGraphs(database))
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: 'Failed to remove the duplicates',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
