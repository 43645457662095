/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'

export const computeTsne = (database, vkgId) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId} = userReducer
  const {authKey, orgId, licenseKey} = authForm

  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/compute`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId}
    if (licenseKey) {
      headers['Authorization'] = `Bearer ${licenseKey}`
    }
    Helpers.fetchPostJson(endpoint, headers, {})
        .then((res) => {
          if (!userReducer.userMessage && res.status !== 'SUCCESS') {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                // eslint-disable-next-line max-len
                graphList: [],
                userMessage: `Failed ${vkgId} node calculation: ${res.error || res.message}`,
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                isSignedIn: false,
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                userMessage: `${vkgId} node calculation completed`,
                notificationType: 2,
                notificationIcon: 'check',
              },
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              // eslint-disable-next-line max-len
              graphList: [],
              isLoading: false,
              userMessage: `Failed ${vkgId} node calculation.`,
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
